<template>
  <div>
    <HeroImage />

    <template v-if="page?.home_pageblocks">
      <template v-for="(section, index) in page.home_pageblocks">
        <component 
        :is="getComponent(section.pageblocks.block)"
        :content="section.pageblocks"
        :sectionIndex="index+1" 
        />
      </template>
    </template>

    <LandingPageNewsletter />

    <LandingPagePreFooter />

    <!-- <div class="page">
      <div class="form prose">
        
        <h1>Drum Rudiments</h1>
        <p>Coming soon.</p>
        <p>Honestly, I know this website looks terrible but <mark>bare with me</mark>. I've been working hard on the audio engine. In a few months it will be banging. Here's why:</p>
        <ul>
          <li>Custom audio engine - flams, drags, ruffs, odd time signatures, triplets, quintuplets, you name it 🥁.</li>
          <li>Velocity sensitive snare sounds 🧨</li>
          <li>Customize your snare sound 🪘 - want a sharp marching snare or a rich, low-tuned Ludwig maple? We've got you covered.</li>
          <li>Customize your metronome sound. Cowbell 🤠 or sinewave..? your choice!</li>
          <li>Save your tempo, monitor your progress, and focus on exercises that challenge and improve your skills ✅</li>
          <li>Basic rudiments, complex rudiments, snare solos and sight-reading challenges.</li>
        </ul>
        <p><strong>Most importantly -> good design.</strong> Honestly, all the drum rudiment apps I've tried look bad or sound bad, or are unintuitive. YouTube has some great content, but I can't save my progress. I've been <a target="_blank" href="https://radiorelativa.eu/">building intuitive websites and apps</a> for years so forgive me for this temporary splash page.</p>
        <p>Subscribe for updates and be the first to know when the app launches.</p>

        <iframe width="540" height="570" src="https://09857474.sibforms.com/serve/MUIFAOvZfZ-JwwVzsT_QSFH7k93w4mGoQNlIzaL8FVWayatLogUIYF2sZf-ztBpK-cRF9rDeEAp-qbGXHCcErVrhkNzjshH8BpKdum1VXdX3BMKWE1_dWkVAtBnHlbkYkQvzKYinmprVB7IP3DzcW6l-gcMRkr5Gct9Obf7QLqhATSk8VLtyKY3UWG_IGR0vLtXTvkxZtwcBV9j3" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;max-width: 100%;"></iframe>

        <p>Lastly, if you <strong>absolutely nerd out</strong> on rhythm and rudiments and want to be involved in some way, email me at alex@drumrudiments.app</p>

      </div>
    </div> -->
  </div>
</template>

<script setup>
const route = useRoute()
const supabase = useSupabaseClient()
const contentSections = useLandingPageSections()

const { $seo } = useNuxtApp()

const getComponent = key => {
  return contentSections[key]
}

const { data: page } = await useAsyncData(
  'page-home', 
  async () => {
    const { data } = await supabase.from('home').select(`
      *,
      hero(*),
      seo(*),
      home_pageblocks(
        *,
        pageblocks(
          *,
          title
        )
      )
    `)
    .maybeSingle()
    return data
  }
)

const seo = computed(() => {
  if (page.value) {
    return $seo(page.value?.seo, route.fullPath)
  }
  return {}
})

useHead(seo)

</script>

<style lang="scss">
.page {
  display: flex;
  justify-content: center;
  font-size: 18px;
  @media (min-width: 450px) {
    font-size: 22px;
  }
}
.form {
  max-width: 500px;
}
</style>