<template>
  <header 
  class="hidden lg:flex h-[60px] shrink-0 items-center gap-2 border-b px-4">
    
    <button 
    class="inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 hover:bg-accent hover:text-accent-foreground h-7 w-7 -ml-1"
    :class="[ optionsStore.sidebarCollapsed ? 'cursor-e-resize' : 'cursor-w-resize' ]"
    data-sidebar="trigger"
    @click="toggleSidebar"
    >
      <Icon name="lucide:panel-left" class="w-[18px] h-[18px]" />
    </button>

    <div
    data-orientation="vertical"
    aria-orientation="vertical"
    role="separator"
    class="shrink-0 bg-gray-300 relative w-px mr-2 h-4"
    />

    <AppBreadCrumbs />
    
  </header>
</template>

<script setup>
const optionsStore = useOptionsStore()
const emit = defineEmits(['toggle-sidebar'])

const toggleSidebar = () => {
  emit('toggle-sidebar')
}
</script>