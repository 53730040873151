<template>
  <div 
  v-if="profile"
  class="relative isolate flex w-full max-lg:flex-col bg-white lg:bg-zinc-950 lg:h-screen lg:overflow-hidden">
    
    <!-- Sidebar on desktop -->
    <div 
    class="fixed inset-y-0 left-0 max-lg:hidden z-10"
    :class="[optionsStore.sidebarCollapsed ? 'w-[60px]' : 'w-[250px] xl:w-[250px]']"
    >
      <AppSidebar :collapsed="optionsStore.sidebarCollapsed" />
    </div>

    <!-- Sidebar on mobile -->
    <TransitionRoot appear :show="showSidebar">
      <Dialog :open="showSidebar" @close="showSidebar = false" class="lg:hidden">

        <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/50"></div>
        </TransitionChild>

        <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0 scale-95"
        enter-to="opacity-100 scale-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100 scale-100"
        leave-to="opacity-0 scale-95"
        >
          <DialogPanel class="fixed z-10 inset-y-0 w-full max-w-80 p-2 transition duration-300 ease-in-out">
            <div class="fixed inset-y-0 w-full max-w-80 p-2 transition duration-300 ease-in-out data-[closed]:-translate-x-full" id="headlessui-dialog-panel-:r4b:" data-headlessui-state="open">
              <div class="flex h-full flex-col rounded-lg bg-zinc-900 shadow-sm ring-1 ring-white/10">
                <div class="px-[6px] pt-3">
                  <span class="relative">
                    <button aria-label="Close navigation" 
                    type="button"
                    @click="showSidebar = false"
                    class="cursor-default relative flex min-w-0 items-center gap-3 rounded-lg p-2 text-left text-base/6 font-medium text-zinc-950 sm:text-sm/5 focus:outline-none focus:ring-0">
                      <span class="absolute left-1/2 top-1/2 size-[max(100%,2.75rem)] -translate-x-1/2 -translate-y-1/2 [@media(pointer:fine)]:hidden" aria-hidden="true">
                      </span>
                      <svg data-slot="icon" viewBox="0 0 20 20" aria-hidden="true" class="w-6 h-6 fill-white">
                        <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z">
                        </path>
                      </svg>
                    </button>
                  </span>
                </div>
                <AppSidebar />
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </Dialog>
    </TransitionRoot>

    <!-- Navbar on mobile -->
    <header class="flex items-center px-4 lg:hidden">
      <div class="py-2.5">
        <span class="relative">
          <button 
          aria-label="Open navigation" 
          class="cursor-default relative flex min-w-0 items-center gap-3 rounded-lg p-2 text-left text-base/6 font-medium text-zinc-950 sm:text-sm/5" 
          type="button" 
          data-headlessui-state=""
          @click="showSidebar = true"
          >
            <span class="absolute left-1/2 top-1/2 size-[max(100%,2.75rem)] -translate-x-1/2 -translate-y-1/2 [@media(pointer:fine)]:hidden" aria-hidden="true">
            </span>
            <svg data-slot="icon" viewBox="0 0 20 20" aria-hidden="true" class="w-6 h-6 fill-black">
              <path d="M2 6.75C2 6.33579 2.33579 6 2.75 6H17.25C17.6642 6 18 6.33579 18 6.75C18 7.16421 17.6642 7.5 17.25 7.5H2.75C2.33579 7.5 2 7.16421 2 6.75ZM2 13.25C2 12.8358 2.33579 12.5 2.75 12.5H17.25C17.6642 12.5 18 12.8358 18 13.25C18 13.6642 17.6642 14 17.25 14H2.75C2.33579 14 2 13.6642 2 13.25Z">
              </path>
            </svg>
          </button>
        </span>
      </div>

      <div class="flex flex-1 justify-center">
        <NuxtLink to="/play" 
        :class="[
          route.path.includes('/rudiment') ? 'max-[919px]:landscape:hidden' : ''        
        ]">
          <LogoBig theme="light" class="w-[100px] h-auto" />
        </NuxtLink>
      </div>

      <div class="py-2.5">
        <span class="relative">
          <Menu 
          as="div"
          class="relative inline-block text-left">
            <MenuButton 
            class="cursor-default relative flex min-w-0 items-center gap-3 rounded-lg text-left text-base/6 font-medium text-zinc-950 sm:text-sm/5 focus:outline-none focus:ring-0"
            ref="userMenuButton"
            >
              <span class="absolute left-1/2 top-1/2 size-[max(100%,2.75rem)] -translate-x-1/2 -translate-y-1/2 [@media(pointer:fine)]:hidden" aria-hidden="true"></span>
              <span data-slot="avatar" class="flex items-center justify-center size-10 inline-grid shrink-0 align-middle [--avatar-radius:20%] [--ring-opacity:20%] *:col-start-1 *:row-start-1 outline outline-1 -outline-offset-1 outline-white/[--ring-opacity] rounded-[--avatar-radius] *:rounded-[--avatar-radius]">
                <Icon class="w-6 h-6 fill-white" name="heroicons:user-circle-20-solid" />
              </span>
            </MenuButton>
            <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
              class="absolute right-0 top-10 min-w-52 z-10 isolate w-max rounded-xl p-1 outline outline-1 outline-transparent focus:outline-none focus:ring-0 overflow-y-auto bg-zinc-900 shadow-lg ring-1 ring-zinc-950/10 ring-inset ring-white/10 grid grid-cols-[auto_1fr_1.5rem_0.5rem_auto] transition"
              >
                <template v-for="item in userNavItems">
                  <AppSidebarItem :type="item.type" :to="item?.to" :label="item?.label" :icon="item?.icon" :function="item?.function" />
                </template>
              </MenuItems>
            </transition>
          </Menu>
        </span>
      </div>
    </header>

    <!-- Content -->
    <main 
    class="flex flex-1 flex-col lg:min-w-0 overflow-auto minimal-scrollbar"
    :class="[optionsStore.sidebarCollapsed ? 'lg:pl-[60px]' : 'lg:pl-[250px] xl:pl-[250px]']"
    >
      <div class="grow bg-white lg:shadow-sm lg:ring-1 lg:ring-zinc-950/5">
        <AppHeader @toggle-sidebar="sidebarToggle" />
        <div 
        v-if="route.path.includes('/rudiment')"
        class="p-4 max-[919px]:landscape:py-1">
          <slot />
        </div>
        <div 
        v-else
        class="p-4">
          <slot />
        </div>
      </div>
    </main>

    <!-- Here, I need a column on xl screens that is shown when the user is on a rudiment page -->
    <aside 
    class="hidden xl:flex bg-white flex-none w-[230px] h-screen border-l"
    v-if="route.path.includes('/rudiment') && routeCollection.length"
    >
      <div class="w-full h-full overflow-y-auto px-2 no-scrollbar">
        <div class="flex flex-col">
          <template v-for="(item, index) in routeCollection">
            <div 
            v-if="canShowRudiment(item.rudiments)"
            :class="[ 
              item.rudiments.slug === route.params.slug ? 'bg-zinc-950' : 'bg-gray-200',
              { 'mt-2' : index === 0 }
            ]"
            class="p-1 rounded w-full flex items-center justify-center mb-2">
              <NuxtLink :to="`/play/rudiment/${item.rudiments.slug}`" class="p-2 w-full items-center justify-center">
                <!-- <Image
                v-if="item.rudiments?.image"
                :data="item.rudiments.image"
                :xs="4"
                :sm="4"
                :md="4"
                :lg="3"
                :xl="3"
                classes="rounded-xl"
                /> -->
                <img
                v-if="item.rudiments?.image"
                :src="'https://cdn.drumrudiments.app/'+item.rudiments.image.filename_disk"
                :height="item.rudiments.image.height"
                :width="item.rudiments.image.width"
                :alt="item.rudiments.title"
                classes="rounded-xl"
                />
                <h2 
                :class="[ 
                  'text-lg font-semibold sm:text-sm/5 mt-2',
                  item.rudiments.slug === route.params.slug ? 'text-white' : 'text-zinc-950',
                  { 'mt-2' : index === 0 }
                ]"
                >
                  {{ item.rudiments.title }}
                </h2>
              </NuxtLink>
            </div>
          </template>
        </div>
      </div>
    </aside>
  </div>
  <Message />
</template>

<script setup>
import { 
  Dialog, 
  DialogPanel,
  TransitionChild, 
  TransitionRoot,
  Menu,
  MenuButton,
  MenuItems
} from '@headlessui/vue'
const config = useRuntimeConfig()
const user = useSupabaseUser()
const supabase = useSupabaseClient()
const optionsStore = useOptionsStore()
const messageStore = useMessageStore()
const userStore = useUserStore()
const { profile } = storeToRefs(userStore)
const route = useRoute()
const { fetchProfile, fetchNotifications, signOut } = useUser()

const allowedStatus = config.public.postStatus?.split(',') || []

const canShowRudiment = rudiment => {
  if (!rudiment.value?.status) {
    return true
  }
  return allowedStatus.includes(rudiment.value.status)
}

const helpLinkClicked = ref(false)
const helpActive = ref(false)
const showSidebar = ref(false)
const userMenuButton = ref(null)
const routeCollection = ref([])
const collectionTitle = ref('')

setTimeout(() => {
  messageStore.clearMessage()
}, 3000)

const sidebarToggle = () => {
  optionsStore.toggleSidebar()
}

watch(user, async () => {
  if (user.value) {
    await fetchProfile()
    await fetchNotifications()
  }
}, { immediate: true })

const checkRoute = async () => {
  
  if (!route.path.includes('/rudiment')) {
    return
  }
  const slug = route.params?.slug
  if (!slug) {
    return
  }
 
  const { data: collection } = await supabase
  .from('rudiments')
  .select(`
    id,
    title,
    slug,
    status,
    collections_rudiments(
      collections(
        title,
        slug
      )
    )
  `)
  .eq('slug', slug)
  .in('status', config.public.postStatus.split(','))
  .maybeSingle()
   
  if (!collection) {
    return
  }

  const collectionSlug = collection?.collections_rudiments[0]?.collections.slug || null

  if (!collectionSlug) {
    return
  }

  const { data: collectionRudiments } = await supabase
  .from('collections')
  .select(`
    collections_rudiments(
      rudiments_sort,
      rudiments(
        title, 
        slug,
        image(
          filename_disk,
          width,
          height
        ),
        rudiments_rudiment_categories(
          rudiment_categories(
            title,
            slug
          )
        )  
      )
    )
  `)
  .eq('slug', collectionSlug)
  .order('rudiments_sort', { referencedTable: 'collections_rudiments', ascending: true })
  .maybeSingle()

  if (!collectionRudiments) {
    return
  }

  collectionTitle.value = collectionRudiments?.title
  routeCollection.value = collectionRudiments?.collections_rudiments
  
}

checkRoute()

const closeMenu = () => {
  showSidebar.value = false
  if (userMenuButton.value?.$el.getAttribute('aria-expanded') === 'true') {
    userMenuButton.value.$el.click()
  }
}

const { data: options, error } = await useAsyncData(
  'site-options', 
  async () => {
    const { data } = await supabase.from('site_options').select('*').maybeSingle()
    return data
  }
)

if (!options.value) {
  throw createError({
    statusCode: 404
  })
}

optionsStore.updateOptions(options.value)

watch(
  () => route.path, 
  () => {
    checkRoute()
    closeMenu()
  }
)

const userSignout = async () => {
  closeMenu()
  await signOut()
}

const intercomObject = ref({
  api_base: 'https://api-iam.intercom.io',
  app_id: 'iazml3fk',
  user_id: null,
  name: null,
  email: null,
  created_at: null
})

if (profile.value?.id) {
  intercomObject.value.user_id = profile.value?.id
  intercomObject.value.name = profile.value?.first_name
  intercomObject.value.email = profile.value?.email
  intercomObject.value.created_at = profile.value?.created_at
}

const helpDesk = async () => {
  if (helpActive.value) return

  if (user.value) {
    const hash = await $fetch('/api/intercom/hash', {
      method: 'POST',
      body: JSON.stringify({
        user_id: user.value.id
      })
    })

    if (!hash) return

    intercomObject.value.user_hash = hash
  }

  useHead({
    script: [
      {
        innerHTML: `
          window.intercomSettings = {
            ...${JSON.stringify(intercomObject.value)}
          };
        `,
        type: 'text/javascript'
      },
      {
        innerHTML: `
          (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/iazml3fk';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();Intercom('show');
        `,
        type: 'text/javascript'
      }
    ]
  })
  helpActive.value = true
  helpLinkClicked.value = true
  setTimeout(() => {
    helpLinkClicked.value = false
  }, 8000)
}

const userNavItems = [
  {
    type: 'link',
    to: '/account',
    label: 'Account settings',
    icon: 'heroicons:user-circle-20-solid',
  },
  {
    type: 'link',
    to: '/account/subscription',
    label: 'Subscription',
    icon: 'heroicons:receipt-refund-20-solid',
  },
  {
    type: 'separator',
  },
  {
    type: 'button',
    label: 'Help',
    icon: 'heroicons:chat-bubble-oval-left-ellipsis-20-solid',
    function: helpDesk,
  },
  {
    type: 'separator',
  },
  {
    type: 'button',
    label: 'Sign out',
    icon: 'heroicons:arrow-right-on-rectangle-20-solid',
    function: userSignout,
  }
]

</script>