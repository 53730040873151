<template>
  <section class="my-12">
    <Container>

      <div class="mx-auto divide-y divide-black">
        <h2 class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
          {{ content.title }}
        </h2>
        <div class="mt-10 space-y-6 divide-y divide-black">
          <div v-for="(faq, index) in content.faqs" :key="faq.q" class="pt-6">
            <div @click="togglePanel(index)" class="cursor-pointer">
              <div class="flex w-full items-start justify-between text-left">
                <h3 class="text-base sm:text-lg md:text-xl lg:text-3xl lg:tracking-tight xl:text-4xl font-semibold leading-7">{{ faq.q }}</h3>
                <span class="ml-6 flex h-7 items-center">
                  <span class="nba rotate-90 text-2xl">></span>
                </span>
              </div>
            </div>
            <div v-show="activePanel === index" class="mt-4 text-block">
              <p class="text-base md:text-lg font-semibold" v-html="faq.a"></p>
            </div>
          </div>
        </div>
      </div>

    </Container>
  </section>
</template>

<script setup>
const props = defineProps({
  content: {
    type: Object,
    required: true,
  }
})

const activePanel = ref(999)

const togglePanel = index => {
  const activeIndex = activePanel.value
  if (index === activeIndex) {
    activePanel.value = 999
  } else {
    activePanel.value = index
  }
}


</script>