<template>
  <Header />
  <div class="pt-[58px] minimal-scrollbar" v-if="siteOptions">
    <slot />
  </div>
  <Footer />
  <Message />
  <Rating />
</template>

<script setup>
const user = useSupabaseUser()
const supabase = useSupabaseClient()
const optionsStore = useOptionsStore()
const messageStore = useMessageStore()
const subStore = useSubStore()
const route = useRoute()

const { fetchProfile, fetchNotifications } = useUser()

const siteOptions = ref(null)

if (!subStore.utmSource) {
  // tracking
  let utmSource = route?.query?.utm_source || null
  let utmMedium = route?.query?.utm_medium || null
  let utmCampaign = route?.query?.utm_campaign || null
  let utmLandingPage = route?.path || null
  if (utmMedium === 'cpc') utmMedium = 'paid'
  if (!utmSource) {
    utmSource = document?.referrer || null
  }
  subStore.addUtm(utmSource, utmMedium, utmCampaign, utmLandingPage)
}

setTimeout(() => {
  messageStore.clearMessage()
}, 3000)

const { data: options, error } = await useAsyncData(
  'site-options', 
  async () => {
    const { data } = await supabase.from('site_options').select('*').maybeSingle()
    return data
  }
)

if (!options.value) {
  throw createError({
    statusCode: 404
  })
}

siteOptions.value = options.value

optionsStore.updateOptions(options.value)

watch(user, async () => {
  if (user.value) {
    await fetchProfile()
  }
})

</script>