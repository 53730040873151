<template>
  <div class="bg-blue px-4">
    <div class="relative isolate overflow-hidden">
      <div class="py-8 sm:py-16 md:py-24 lg:flex lg:py-4 lg:gap-8">
        <div class="text-center mx-auto max-w-2xl lg:flex lg:flex-col lg:justify-between lg:w-[40%] lg:m-0">
          <div class="hidden lg:block nba text-white text-right text-sm xl:text-lg">How to use the app ></div>
          <div>
            <h1 class="text-4xl sm:text-4xl md:text-5xl lg:text-[4.7vw] xl:text-[4.5vw] font-bold tracking-tight text-white lg:text-left xxl:text-[3.8vw] xxxl:text-[3.2vw]">
              Drum Rudiments<span class="hidden"> – </span><span class="block font-semibold tracking-tighter text-2xl lg:text-[2.8vw] xl:mt-4 xl:text-[2.9vw] xxl:text-[2.5vw] xxxl:text-[2.2vw]">Tools for drummers</span>
            </h1>
          </div>
          <p class="text-sm sm:text-base md:text-lg lg:text-[1.9vw] max-w-[340px] lg:max-w-none font-semibold tracking-tight text-white mt-4 mb-4 mx-auto lg:text-left xl:text-[1.5vw] lg:leading-[1.8rem] xl:leading-[2.3rem] xxl:leading-[2.5rem] xxl:text-[1.5vw] xxxl:text-[1.3vw]">
            Master essential rudiments, track your progress and practice at your own pace 🥁
          </p>
        </div>
        <div class="mt-8 mx-auto max-w-3xl lg:max-w-none lg:mt-0 lg:w-[60%] lg:m-0 xxl:w-[70%]">
          <div style="position:relative;padding-top:56.25%;" class="rounded-lg overflow-hidden">
            <iframe src="https://iframe.mediadelivery.net/embed/358858/72ca17b4-4700-415c-a20e-2082a6859c0f?autoplay=false&loop=false&muted=false&preload=true&responsive=true" loading="lazy" style="border:0;position:absolute;top:0;height:100%;width:100%;" allow="accelerometer;gyroscope;encrypted-media;" allowfullscreen="true"></iframe>
          </div>
          <div class="nba text-white text-center text-sm lg:hidden mt-4">How to use the app ^</div>
        </div>
      </div>
    </div>
  </div>
</template>