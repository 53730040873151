<template>
  <section class="grid grid-cols-2 md:grid-cols-5 md:grid-rows-4 gap-2 items-center px-4 py-8 sm:py-16">
    <NuxtLink 
    to="/join" 
    class="relative bg-black hover:bg-red aspect-square md:col-span-2 md:row-span-4 rounded-lg"
    >
      <SvgHexagon />
      <div class="absolute z-99 left-[50%] top-[50%] z-999 -translate-y-[50%] -translate-x-[50%] text-center">
        <span class="text-black text-base sm:text-2xl lg:text-4xl xl:text-6xl font-bold">
          7-day<br>free trial
        </span>
      </div>
    </NuxtLink>
    <div class="h-[100%] hidden md:grid grid-cols-1 grid-rows-4 gap-y-2 row-span-4">
      <NuxtLink
      to="/join"
      v-for="usp in usps"
      class="bg-black hover:bg-red h-full rounded-lg relative"
      >
        <div class="absolute z-99 w-[100%] p-2 left-[50%] top-[50%] z-999 -translate-y-[50%] -translate-x-[50%] text-center">
          <span class="text-white text-base lg:text-xl xl:text-2xl font-bold">{{ usp }}</span>
        </div>
      </NuxtLink>
    </div>
    <NuxtLink 
    to="/join"
    class="relative bg-black hover:bg-red aspect-square md:col-span-2 md:row-span-4 rounded-lg"
    >
      <SvgCircle />
      <div class="absolute z-99 left-[50%] top-[50%] z-999 -translate-y-[50%] -translate-x-[50%] text-center">
        <span class="text-black text-base sm:text-2xl lg:text-4xl xl:text-6xl font-bold">Try now</span>
      </div>
    </NuxtLink>
  </section>
</template>

<script setup>
const usps = [
  'Save your tempo',
  'Customize your sounds',
  'Built for snare drum',
  'Personalised content'
]
</script>