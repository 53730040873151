const defaultTitle = 'Drum Rudiments | Practice at Your Pace'
const defaultDescription = 'Master the essential drum rudiments, track your progress and go from pad to pro. 🥁'
const defaultImage = 'https://drumrudiments.app/images/hero.png'

function imgPath(path) {
  const cdnUrl = 'https://cdn.drumrudiments.app'
  const bucket = 'https://db.drumrudiments.app/storage/v1/object/public/drumapp'
  if (!path) {
    return defaultImage
  }
  if (path?.filename_disk) {
    return cdnUrl + '/' + path.filename_disk
  }
  if (path.includes(bucket)) {
    const newImgPath = path.split(bucket)[1]
    return cdnUrl + '/' + newImgPath
  }
  if (!path.includes('http') && !path.includes('png') && !path.includes('jpeg') && !path.includes('jpg')) {
    return cdnUrl + '/' + path + '.png'
  }
  return path
}

function useOpenGraphMeta(content, url, metaImage = null) {
  const { meta_title, meta_description } = content
  return [
    {
      hid: 'og:description',
      property: 'og:description',
      content: meta_description,
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: meta_title,
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: imgPath(metaImage),
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: `https://drumrudiments.app${url === '/' ? '' : url}`,
    },
    {
      hid: 'og:site_name',
      name: 'og:site_name',
      content: 'Drum Rudiments',
    },
    {
      hid: 'twitter:card',
      name: 'twitter:card',
      content: `summary_large_image`,
    },
    {
      hid: 'twitter:title',
      name: 'twitter:title',
      content: meta_title,
    },
    {
      hid: 'twitter:description',
      name: 'twitter:description',
      content: meta_description,
    },
    {
      hid: 'twitter:site',
      name: 'twitter:site',
      content: `https://drumrudiments.app${url === '/' ? '' : url}`,
    },
    {
      hid: 'twitter:image',
      name: 'twitter:image',
      content: imgPath(metaImage),
    },
  ]
}

export default defineNuxtPlugin(() => {
  return {
    provide: {
      seo: (meta, url) => {
        if (!meta) {
          return {
            title: defaultTitle,
            meta: [
              {
                hid: 'description',
                name: 'description',
                content: defaultDescription,
              },
              ...useOpenGraphMeta({
                meta_title: defaultTitle,
                meta_description: defaultDescription,
              }, url),
            ],
          }
        }
        
        const { meta_title, meta_description, meta_image } = meta
        
        let socialMetas = []
        
        socialMetas = useOpenGraphMeta({
          meta_title: meta_title || defaultTitle,
          meta_description: meta_description || defaultDescription,
        }, url, meta_image || defaultImage) 

        const metas = {
          title: meta_title || defaultTitle,
          link: [
            {
              hid: 'canonical',
              rel: 'canonical',
              href: `https://drumrudiments.app${url === '/' ? '' : url}`,
            },
          ],
          meta: [
            {
              hid: 'description',
              name: 'description',
              content: meta_description,
            },
            ...socialMetas.flat(),
          ],
        }
        return metas
      }
    }
  }
})