<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full justify-center p-4 text-center items-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-black text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <button @click="closePanel" class="absolute top-4 right-4 text-gray-900 hover:text-gray-500 focus:outline-none">
                <Icon name="iconoir:xmark" class="w-6 h-6" />
              </button>

              <div class="p-3">
                <div class="bg-white rounded-2xl p-6">
                  <h1 class="text-2xl font-bold tracking-tight text-zinc-950 md:text-3xl">Thank you!</h1>
                  <p v-if="currentRating <= 3" class="mt-2 text-base text-zinc-800">
                    We really appreciate your feedback. We hope to improve the platform and content based on your submission. If you have any questions, email support@drumrudiments.app and we will happily assist you.
                  </p>
                  <p v-else class="mt-2 text-base text-zinc-800">
                    We really appreciate good feedback. If you have time, we would love for you to <a href="https://au.trustpilot.com/review/drumrudiments.app" target="_blank" class="text-blue underline">write a review on TrustPilot</a> so that other people can find us too.
                  </p>
                </div>
              </div>

            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue'

const isOpen = ref(false)
const currentRating = ref(null)
const router = useRouter()

const closePanel = () => {
  isOpen.value = false
  router.replace({ query: {} })
}

watch(() => router.currentRoute.value.query, (newQuery) => {
  if (newQuery.t === 'true' && newQuery.r) {
    isOpen.value = true
    currentRating.value = parseInt(newQuery.r) || 3
  }
}, { immediate: true })

onMounted(() => {
  const { t, r } = router.currentRoute.value.query
  if (t === 'true' && r) {
    isOpen.value = true
    currentRating.value = parseInt(r) || 3
  }
})
</script>