import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAppBreadCrumbs, LazyAppButtonOctagon, LazyAppDropdown, LazyAppHeader, LazyAppLogo, LazyAppLogoSquare, LazyAppSectionTitle, LazyAppSidebar, LazyAppSidebarItem, LazyArticleContent, LazyArticleEmbed, LazyArticleFaqs, LazyArticleGallery, LazyArticleImage, LazyArticleQuote, LazyArticleRudiment, LazyBlogCard, LazyBlogCategories, LazyButton, LazyCardCollection, LazyCardRudiment, LazyCheckout, LazyCongrats, LazyContainer, LazyCountriesOptions, LazyExplore, LazyFavourite, LazyFooter, LazyHeader, LazyHeroImage, LazyImage, LazyLandingPageArticles, LazyLandingPageChallenge, LazyLandingPageFaqs, LazyLandingPageNewContent, LazyLandingPageNewsletter, LazyLandingPagePreFooter, LazyLandingPageSignUp, LazyLandingPageText, LazyLogoBig, LazyLogoFooter, LazyMessage, LazyMiniPlayer, LazyNotation, LazyPlayer, LazyRating, LazySecure, LazySvgCircle, LazySvgFb, LazySvgHexagon, LazySvgIg, LazySvgTk, LazySvgYt, LazyWebsiteSidebar, LazyNuxtMarquee, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["AppBreadCrumbs", LazyAppBreadCrumbs],
["AppButtonOctagon", LazyAppButtonOctagon],
["AppDropdown", LazyAppDropdown],
["AppHeader", LazyAppHeader],
["AppLogo", LazyAppLogo],
["AppLogoSquare", LazyAppLogoSquare],
["AppSectionTitle", LazyAppSectionTitle],
["AppSidebar", LazyAppSidebar],
["AppSidebarItem", LazyAppSidebarItem],
["ArticleContent", LazyArticleContent],
["ArticleEmbed", LazyArticleEmbed],
["ArticleFaqs", LazyArticleFaqs],
["ArticleGallery", LazyArticleGallery],
["ArticleImage", LazyArticleImage],
["ArticleQuote", LazyArticleQuote],
["ArticleRudiment", LazyArticleRudiment],
["BlogCard", LazyBlogCard],
["BlogCategories", LazyBlogCategories],
["Button", LazyButton],
["CardCollection", LazyCardCollection],
["CardRudiment", LazyCardRudiment],
["Checkout", LazyCheckout],
["Congrats", LazyCongrats],
["Container", LazyContainer],
["CountriesOptions", LazyCountriesOptions],
["Explore", LazyExplore],
["Favourite", LazyFavourite],
["Footer", LazyFooter],
["Header", LazyHeader],
["HeroImage", LazyHeroImage],
["Image", LazyImage],
["LandingPageArticles", LazyLandingPageArticles],
["LandingPageChallenge", LazyLandingPageChallenge],
["LandingPageFaqs", LazyLandingPageFaqs],
["LandingPageNewContent", LazyLandingPageNewContent],
["LandingPageNewsletter", LazyLandingPageNewsletter],
["LandingPagePreFooter", LazyLandingPagePreFooter],
["LandingPageSignUp", LazyLandingPageSignUp],
["LandingPageText", LazyLandingPageText],
["LogoBig", LazyLogoBig],
["LogoFooter", LazyLogoFooter],
["Message", LazyMessage],
["MiniPlayer", LazyMiniPlayer],
["Notation", LazyNotation],
["Player", LazyPlayer],
["Rating", LazyRating],
["Secure", LazySecure],
["SvgCircle", LazySvgCircle],
["SvgFb", LazySvgFb],
["SvgHexagon", LazySvgHexagon],
["SvgIg", LazySvgIg],
["SvgTk", LazySvgTk],
["SvgYt", LazySvgYt],
["WebsiteSidebar", LazyWebsiteSidebar],
["NuxtMarquee", LazyNuxtMarquee],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
